import developersPage from '../pages/developers/developers.page';
import apiPage from '../pages/developers/api/api.page';
import tutorialsPage from '../pages/developers/tutorials/tutorials.page';
import jsLibraryPage from '../pages/developers/js-library/js-library.page';

export default developersRouting;

angular.module('arrivedo')
  .config(developersRouting);

developersRouting.$inject = ['$stateProvider'];

function developersRouting($stateProvider) {

  // $urlRouterProvider.otherwise('/');
  $stateProvider
    .state('developers', {
      url: '/developers',
      template: developersPage.template,
      //templateUrl: '../pages/developers/developers.page.html',
      controller: developersPage.controller,
      resolve: {
        lazyLoad: ['$q', '$ocLazyLoad', function ($q, $ocLazyLoad) {
          let deferred = $q.defer();
          require.ensure([], function () {
            // All the code here, plus the required modules
            // will be bundled in a separate file.
            let module = require('../modules/developers.module');
            $ocLazyLoad.inject({ //.inject({
              name: module.default
            });
            deferred.resolve(module);
          });
          return deferred.promise;
        }]
      }
    })

    .state('developers-api', {
      url: '/developers/api',
      template: apiPage.template,
      // templateUrl: '../pages/developers/api/api.page.html',
      controller: apiPage.controller,
      resolve: {
        lazyLoad: ['$q', '$ocLazyLoad', function ($q, $ocLazyLoad) {
          let deferred = $q.defer();
          require.ensure([], function () {
            // All the code here, plus the required modules
            // will be bundled in a separate file.
            let module = require('../modules/developers.module');
            $ocLazyLoad.inject({ //.inject({
              name: module.default
            });
            deferred.resolve(module);
          });
          return deferred.promise;
        }]
      }
    })

    .state('developers-js', {
      url: '/developers/js-library',
      template: jsLibraryPage.template,
      controller: jsLibraryPage.controller,
      resolve: {
        lazyLoad: ['$q', '$ocLazyLoad', function ($q, $ocLazyLoad) {
          let deferred = $q.defer();
          require.ensure([], function () {
            // All the code here, plus the required modules
            // will be bundled in a separate file.
            let module = require('../modules/developers.module');
            $ocLazyLoad.inject({ //.inject({
              name: module.default
            });
            deferred.resolve(module);
          });
          return deferred.promise;
        }]
      }
    })

    .state('developers-tutorials', {
      url: '/developers/tutorials',
      template: tutorialsPage.template,
      controller: tutorialsPage.controller,
      resolve: {
        lazyLoad: ['$q', '$ocLazyLoad', function ($q, $ocLazyLoad) {
          let deferred = $q.defer();
          require.ensure([], function () {
            // All the code here, plus the required modules
            // will be bundled in a separate file.
            let module = require('../modules/developers.module');
            $ocLazyLoad.inject({ //.inject({
              name: module.default
            });
            deferred.resolve(module);
          });
          return deferred.promise;
        }]
      }
    })
}
