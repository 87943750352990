import template from './footer.directive.html';
// import './footer.directive.scss';

export default builder.directive({
  name: 'footerDirective',
  func: directive
});

function directive(){
  return {
    // templateUrl: './components/footer/footer.directive.html',
    template: template,
    replace : true,
    restrict : 'E',
    scope : {},
  };
}
