import template from './developers.page.html';

export default {
  template,
  controller,
};

controller.$inject = ['$scope', '$rootScope'];
function controller($scope, $rootScope) {

  activate();

  ////////////// functions ////////////////


  async function activate() {
    console.log('developers page is here!')
  }

}
