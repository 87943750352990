// import lazyLoadService from '../../../services/lazy-load.service';
import Prism from 'prismjs';
import template from './api.page.html';

export default {
  template,
  controller,
};

controller.$inject = ['$scope', '$rootScope'];
function controller($scope, $rootScope) {

  activate();

  ////////////// functions ////////////////


  async function activate() {
    console.log('developers page is here!');

    Prism.highlightAll();

    // lazyLoadService.load([
    //   'https://cdn.redoc.ly/redoc/latest/bundles/redoc.standalone.js'
    // ])
    //   .then(function () {

    //     Redoc.init('https://s3.amazonaws.com/arrivedo-dev-portal/swagger.json', {
    //       // scrollYOffset: 72,
    //       hideDownloadButton: true,
    //       disableSearch: true,
    //       nativeScrollbars: true
    //     }, document.getElementById('redoc-container'), function (error) {
    //       console.log(error);
    //     });

    //   });

  }

}
