// import lazyLoadService from '../../services/lazy-load.service';
// import utils from '../../services/utils.service';

import template from './home.page.html';
// import styles from './home.page.scss';
// import * as $ from 'jquery';
require('magnific-popup');
require('owl-carousel');

// console.log('homepage controller loaded');

export default {
  template,
  controller,
};

controller.$inject = ['$scope', '$stateParams', '$rootScope'];
function controller($scope, $stateParams, $rootScope){

  // $scope.callToActionClicked = callToActionClicked;
  // $scope.sent_form_successfully = false;

  activate();

  ////////////// functions ////////////////


  async function activate(){

    // $scope.ab_version = $stateParams.abv;
    $scope.tab = 1;
    console.log('activate homepage controller');

    $scope.setTab = function(newTab){
      console.log({newTab});
      console.warn('at set tab');
      if (newTab == $scope.tab) return;
      const prev_ng = document.getElementById(`${$scope.tab}_example_ng`);
      const prev_elem = document.getElementById(`${$scope.tab}_example_art`);
      prev_elem.classList.remove('article-animation-size', 'fadeInBottom-article-size');
      prev_ng.classList.remove('ng-animation', 'fadeInBottom-ng');
      $scope.tab = newTab;
      $scope.animateExample($scope.tab);
    };

    $scope.isSet = function(tabNum){
      return $scope.tab === tabNum;
    };

    $scope.should_animate = true;

    $scope.animateExample = (tab) => {
      const ng = document.getElementById(`${tab}_example_ng`);
      const article = document.getElementById(`${tab}_example_art`);
      if (ng && isElementInViewport(ng) &&
          article && isElementInViewport(article)) {
        article.classList.add('article-animation-size', 'fadeInBottom-article-size');
        ng.classList.add('ng-animation', 'fadeInBottom-ng');
      }
    };

    $scope.animateBenefits = () => {
      const animates = Array.prototype.slice.call(document.querySelectorAll('.benefit-animation'));
      const should_animate = animates.every(a => isElementInViewport(a));
      if (animates && animates.length > 0 && should_animate) {
        animates.forEach((a, indx) => {
          a.classList.remove('benefit-animation');
          a.classList.add('animated', 'bounce', `animated-example-${indx + 1}`);
        });
      }
    };


    $scope.article_video = document.getElementById('video-article');
    $scope.map_video = document.getElementById('video-map');

    $scope.article_video_play = false;
    $scope.map_video_play = false;


    $scope.article_video.addEventListener('canplay', () => {
      $scope.article_video_play = true;
    });

    $scope.map_video.addEventListener('canplay', () => {
      $scope.map_video_play = true;
    });

    $scope.article_video.addEventListener('ended', () => {
      $scope.map_video.currentTime = 0;
      $scope.article_video.play();
      $scope.map_video.play();
    });

    const videosReady = setInterval(() => {
      // console.log(document.readystate);
      if ($scope.map_video_play && $scope.article_video_play &&
          document.readystate !== 'loading' && !$rootScope.mobile) {
        document.querySelectorAll('.v5-header-video').forEach(d => {
          d.style.visibility = 'visible';
          d.style.display = 'inline-block';
        });
        
        if(document.getElementById('ph-video') !== null){
          document.getElementById('ph-video').style.visibility = 'hidden';
          document.getElementById('ph-video').style.display = 'none';
        }
        
        $scope.map_video.play();
        $scope.article_video.play();
        clearInterval(videosReady);
      }
    }, 1500);

    if (!$rootScope.mobile) {
      window.addEventListener('scroll', function(e) {
        $scope.animateExample($scope.tab);
        $scope.animateBenefits();
      });
    } else {
      $scope.article_video.style.display = $scope.map_video.style.display = 'none';
    }

    function isElementInViewport(element){
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }


    // lazy loading images
    const lazyImages = [].slice.call(document.querySelectorAll(".lazy")); // select images to lazy load
    loadImages(lazyImages);

    function loadImages(lazyImages = []) {
      if (!lazyImages || lazyImages.length == 0) return;
      
      const options = {
        rootMargin: '-60px 0px 0px 0px'
      };
  
      const loadImage = (dom_element, lazyImageObserver = null) => {
        const lazyImage = dom_element;
        const parent = lazyImage.parentNode;
        const parentIsPicture = parent.tagName === 'PICTURE'; 
        if (parentIsPicture) {
          for (let j = 0; j < parent.children.length; j++) {
            const pictureChild = parent.children[j];;
            if (pictureChild.tagName === 'SOURCE') {
              const srcset = pictureChild.getAttribute('data-srcset');
              if (srcset) pictureChild.setAttribute('srcset', srcset);
            } 
          }
        }
        lazyImage.setAttribute('srcset', lazyImage.getAttribute('data-srcset'));
        lazyImage.setAttribute('src', lazyImage.getAttribute('data-src'));
        
        if (lazyImageObserver) {
          lazyImageObserver.unobserve(lazyImage);
        }
      };
  
      if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype) {
        let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
          entries.forEach(function(entry) {
            if (entry.isIntersecting) {           
              const dom_element = entry.target;
              loadImage(dom_element, lazyImageObserver);
            }
          });
        }, options);
        lazyImages.forEach(function(lazyImage) {
          lazyImageObserver.observe(lazyImage);
        });
      } else { // load all images
        lazyImages.forEach((lazyImage) => {
          loadImage(lazyImage);
        });
      }
    }

    // Using jQuery 3.x
    $(document).ready(function() {
      $('.js-v5-watch-video').magnificPopup({
        type: 'iframe',
        closeOnBgClick: false,
        iframe: {
          patterns: {
            youtube: {
              index: 'youtube.com',
              src: 'https://www.youtube.com/embed/JY2jjrHCcBM?autoplay=1&amp;mute=1&amp;rel=0'
            }
          }
        }
      });
  
      $('.js-v4-testimonials-slider').owlCarousel({
        loop: false,
        items: 1,
        dots: true,
        nav: false
      });
  
    });
  }

}
