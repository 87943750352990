export default builder.service({
  name: 'articlePlacesService',
  inject: ['$http', '$log', '$q', '$timeout'],
  func: service
});

function service($http, $log, $q, $timeout) {

  var service = {}, places, currIndex;
  reset();

  service.addPlace = addPlace;
  service.reset = reset;

  return service;

  ///////////////// functions /////////////////

  function addPlace(placeId, placeName) {
    var place = places[placeId];
    if (place) {
      return place.index;
    }
    place = {
      name: placeName,
      index: currIndex
    };
    places[placeId] = place;
    currIndex++;
    return place.index;
  }

  function reset() {
    places = {};
    currIndex = 1;
  }
}