/**
 *
 * notFoundDirective
 * Shows not found message
 *
 */

import template from './not-found.directive.html';
// import styles from './not-found.directive.scss';

export default builder.directive({
  name: 'notFound',
  inject: [],
  func: directive
});

function directive() {
  return {
    template: template,
    replace: true,
    restrict: 'E',
    link: link
  };

  function link(scope, elem) {

    scope.$on('not-found', showBanner);
    scope.$on('$stateChangeStart', hideBanner);


    ///// functions

    function showBanner() {
      elem.addClass('visible');
    }

    function hideBanner() {
      elem.removeClass('visible');
    }

  }
}