/*
 *
 * Config service
 * Created by: unknown
 * Refactored by: Israel Cruz @dantaex
 *
 * This was refactored using:
 * https://github.com/johnpapa/angular-styleguide
 *
*/

export default builder.service({
  name         :'lazyLoadService',
  inject       : ['$http', '$log', '$q', '$timeout'],
  func         : service
});

function service($http, $log, $q, $timeout) {

  var service = {};
  
  service.load = load;
  service.loadImages = loadImages;
  service.loaded_css = {};
  service.loaded_js = {};

  return service;

  ///////////////// functions /////////////////

  function isMyScriptLoaded(url) {
    var scripts = document.getElementsByTagName('script');
    for (var i = scripts.length; i--;) {
        if (scripts[i].src == url) {
          return true;
        } 
    }
    return false;
  }

  function isMyLinkLoaded(url) {
    var scripts = document.getElementsByTagName('link');
    for (var i = scripts.length; i--;) {
      if (scripts[i].href == url){
        return true;
      } 
  }
    return false;
  }

  function load(input) {
    const def = $q.defer();

    if( typeof input === 'string'){
      insertFile(input)
        .then(() => def.resolve());
    } else if( input instanceof Array) {

      const promises = [];
      for (var i = 0; i < input.length; i++) {
        promises.push(insertFile(input[i]));
      }
      return $q.all(promises);

    }

    return def.promise;


    function insertFile(input){
      if(input.match(/((stripe)|(\.js$))/)){
        if(Object.keys(service.loaded_js).indexOf(input) > 0){
          if(service.loaded_js[input]){
            return;
          } else {
            $timeout(()=>{insertFile(input)}, 500);
          }
        } else {
          service.loaded_js[input] = false;
          return insertJS(input);
        }
      } else if( input.match(/\.css$/) ){
        if(Object.keys(service.loaded_css).indexOf(input) > 0){
          if(service.loaded_css[input]){
            return;
          } else {
            $timeout(()=>{insertFile(input)}, 500);
          }
        } else {
          service.loaded_css[input] = false;
          return insertCSS(input);
        }
      }        
    }


    function insertJS(filename){
      var deff = $q.defer();
      // Insert it as JS
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = filename;
      script.addEventListener('load',() => {
        service.loaded_js[filename] = true;
        deff.resolve();
      }, false);
      document.body.insertBefore(script, document.body.firstChild);
      return deff.promise;
    }


    function insertCSS(filename){
      const deff = $q.defer();
      const head = document.getElementsByTagName('head')[0];
      const style = document.createElement('link');
      style.rel = 'preload'; //setAttribute
      style.as = 'style';
      style.href = filename;
      head.appendChild(style);
      style.addEventListener('load',() => {
        service.loaded_css[filename] = true;
        deff.resolve();
      }, false);
      return deff.promise;
    }

  }

  function loadImages(lazyImages = []) {
    if (!lazyImages || lazyImages.length == 0) return;
    
    const options = {
      rootMargin: '-60px 0px 0px 0px'
    };

    const loadImage = (dom_element, lazyImageObserver = null) => {
      const lazyImage = dom_element;
      const parent = lazyImage.parentNode;
      const parentIsPicture = parent.tagName === 'PICTURE'; 
      if (parentIsPicture) {
        for (let j = 0; j < parent.children.length; j++) {
          const pictureChild = parent.children[j];;
          if (pictureChild.tagName === 'SOURCE') {
            const srcset = pictureChild.getAttribute('data-srcset');
            if (srcset) pictureChild.setAttribute('srcset', srcset);
          } 
        }
      }
      lazyImage.setAttribute('srcset', lazyImage.getAttribute('data-srcset'));
      lazyImage.setAttribute('src', lazyImage.getAttribute('data-src'));
      
      if (lazyImageObserver) {
        lazyImageObserver.unobserve(lazyImage);
      }
    };

    if ("IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype) {
      let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {           
            const dom_element = entry.target;
            loadImage(dom_element, lazyImageObserver);
          }
        });
      }, options);
      lazyImages.forEach(function(lazyImage) {
        lazyImageObserver.observe(lazyImage);
      });
    } else { // load all images
      lazyImages.forEach((lazyImage) => {
        loadImage(lazyImage);
      });
    }
  }
}
