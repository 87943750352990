// import './navbar.directive.scss';
import template from './navbar.directive.html';
// import configService from '../../services/config.service';
// import currentUserService from '../../services/current-user.service';
// import preferencesService from '../../services/preferences.service';
// import lazyLoadService from '../../services/lazy-load.service';
// import analyticsEventsService from '../../services/analytics-events.service';

// used inside template
// import userAvatar from '../user-avatar/user-avatar.directive';

export default builder.directive({
  name: 'universalNavbar',
  inject: ['$state', '$timeout', '$stateParams', '$rootScope', '$location'],
  func: directive
});

function directive($state, $timeout, $stateParams, $rootScope, $location) {

  return {
    scope: {
      current: '@',
      menu_open_event: '@menuOpenEvent',
      onboarding_signup_mode: '=onboardingSignupMode',
      onhotels: '=',
      onwriters: '=',
      business_to_business: '=businessToBusiness',
      state: '@',
      wbp: '=parentctrl',
      include_search: '=includeSearch',
      theme: '@',
      on_homepage: '=onHomepage',
      button_enabled: '=regButtonEnabled',
      dev: '=',
      is_full: '=isFull'
    },
    // templateUrl: './components/navbar/navbar.directive.html',
    template: template,
    replace: true,
    link: link,
    restrict: 'E'
  };

  function link(scope, element) {
    // Add smooth scrolling to all links
    $('a.js-explore-content').on('click', function (event) {

      // Make sure this.hash has a value before overriding default behavior
      if (this.hash !== "") {
        // Prevent default anchor click behavior
        event.preventDefault();

        // Store hash
        let hash = this.hash;

        // Using jQuery's animate() method to add smooth page scroll
        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
        $('html, body').animate({
          scrollTop: $(hash).offset().top
        }, 800, function () {

          // Add hash (#) to URL when done scrolling (default click behavior)
          // window.location.hash = hash;
        });
      } // End if
    });
  }
}