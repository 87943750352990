// import lazyLoadService from '../../../services/lazy-load.service';
import Prism from 'prismjs';
import template from './js-library.page.html';

export default {
  template,
  controller,
};

controller.$inject = ['$scope', '$rootScope'];
function controller($scope, $rootScope) {

  activate();

  ////////////// functions ////////////////


  async function activate() {
    console.log('js library page is here!');

    Prism.highlightAll();

    // instance methods
    let coll = document.getElementsByClassName("collapsible");
    let i;
    for (i = 0; i < coll.length; i++) {
      coll[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var content = this.nextElementSibling;
        if (content.style.maxHeight) {
          content.style.maxHeight = null;
        } else {
          content.style.maxHeight = content.scrollHeight + "px";
        }
      });
    }
  }

}
