/**
 *
 * Article Body Directive
 *
 * Check TODO labels below
 *
 */

export default builder.directive({
  name: 'articleBody',
  inject: [
    '$compile',
    '$sce',
    '$rootScope',
    '$window',
    '$timeout',
    'articlePlacesService'
  ],
  func: directive
});

function directive(
  $compile,
  $sce,
  $rootScope,
  $window,
  $timeout,
  articlePlacesService
) {

  return {
    scope: {
      articleBody: '=',
      article: '='
    },
    link: link,
    template: '<div id="article"></div>',
    replace: true,
    restrict: 'E'
  };


  function link(scope, element, attrs) {

    var video_ids = [];
    var orderedIds = [];

    articlePlacesService.reset();
    element.html(scope.article.body);
    $compile(element.contents())(scope);
    getOrderedPlaces(element, scope.article, scope.article.places);

    scope.$watch('article.body', function () {
      if (!scope.article || !scope.article.body) {
        return;
      }
      articlePlacesService.reset();
      element.html(scope.article.body, scope.article);
      $compile(element.contents())(scope);
      getOrderedPlaces(element, scope.article, scope.article.places);
      configureImages(element);
      $rootScope.$broadcast('article-before-read', scope.article);
      configureVideos(element);
    });

    function getOrderedPlaces(element, article, places) {

      if (!article) {
        return;
      }

      var orderedPlaces = element.find('.placename');
      var placesCount = orderedPlaces.length;
      
      for (var i = 0; i < placesCount; i++) {
        var place = orderedPlaces[i];
        // var placeId = place.getAttribute("href").replace(/\/place\/[a-z]{2}\//,"");
        var placeId = place.getAttribute("data-place-id");

        var order = i + 1;
        place.setAttribute('id', 'place-' + placeId);
        place.setAttribute('data-place-id', placeId);
        place.setAttribute('data-place-index', places[i].index);
        place.setAttribute('data-place-order', order);

        place.addEventListener('click', function didClick(e) {
          e.preventDefault();
          var clickedPlace = e.currentTarget;
          var clickedPlaceId = clickedPlace.getAttribute('data-place-id');
          var clickedPlaceOrder = clickedPlace.getAttribute('data-place-order');
          goToPlace(clickedPlaceId, clickedPlaceOrder, true);
        }, false);
        orderedIds.push(placeId);
      }

      $rootScope.$broadcast('load-article-markers', article, orderedIds);
      if (orderedIds[0]) {
        $rootScope.$emit('load-active-place', orderedIds[0]);
      }
    }

    function configureImages(element) {
      var images = element.find('.article-image');
      var imagesCount = images.length;
      for (var k = 0; k < imagesCount; k++) {
        var image = images[k];
        // $scope.article.real_images.push(image);

        if (!image.alt) {
          image.alt = image.getAttribute('data-attribution');
        }
      }

    }



    // TODO: remove jQuery dependency
    function configureVideos(element) {
      element.find('figure.embedded-video').each(function () {
        var $fig_vid = $(this);
        var video = {
          source: $fig_vid.data('source'),
          provider: $fig_vid.data('provider')
        };
        video_ids.push(video);

        if (video.provider === 'youtube') {
          var $iframe = $fig_vid.find('iframe');
          if (!$iframe.length) {
            $iframe = $('<iframe id="article-video-' + video.source + '" src="https://www.youtube.com/embed/' + video.source + '?rel=0&amp;showinfo=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen style="height:400px;width:100%;"></iframe>');
            $fig_vid.append($iframe);
          }
        }

      });

    }



    function goToPlace(placeId, order, clicked) {
      if (placeId) {

        //Let know map directive so it centers the map on this
        $rootScope.$broadcast('article-place-visible', placeId, order);

      }
    }



    // function getVenueDetails(foursquare_id){
    //   if(foursquare_id){
    //     //let know the placeId in order to get place details from 4sq
    //     $rootScope.$broadcast('article-place-4sq',placeId);
    //   }
    // }


  }  // link
}
