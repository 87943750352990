// import lazyLoadService from '../../../services/lazy-load.service';
import Prism from 'prismjs';
import template from './tutorials.page.html';

export default {
  template,
  controller,
};

controller.$inject = ['$scope', '$rootScope'];
function controller($scope, $rootScope) {

  activate();

  ////////////// functions ////////////////


  async function activate() {
    console.log('tutorials page is here!');

    Prism.highlightAll();
  }

}
