/**
 *
 * This is our module builder.
 *
 * When we decided to move from Grunt to Webpack we came across an obvious
 * issue: AngularJS module system.

 * Back in the day when AngularJS was king, Webpack didn't exist, and
 * concatenation was the bundling technique. AngularJS developed a way to
 * _inject_ its dependencies for every controller, factory, service and
 * directive. We have modules and we get these modules to declare any of
 * the previous objects, this is the way angular organises itself so no matter
 * what the order of the concatenated files is.
 *
 * Now that we have AMD modules, browserify, require.js and webpack, code
 * modularity is better handled by _imports_ and _exports_ like in any other
 * programming language.
 *
 * This is our attempt to make AngularJS module system compatible with webpack
 * modules. We will have a different Angular module for each file, so we can
 * reuse it anywhere, even in a different Angular App.
 *
 * Of course we could declare modules in every file, but we would have to name
 * the module, then name the service/factory/controller and for us, it seems way
 * more clear and organized this way.
 *
 */

import angular from 'angular';

export default {
  directive : buildDirective,
  service : buildService,
  factory : buildService,
  controller : buildController,
  filter : buildFilter
};

function buildDirective(options){
  return build('directive',options);
}

function buildService(options) {
  return build('factory',options);
}

function buildController(options){
  return build('controller',options);
}

function buildFilter(options){
  return build('filter',options);
}

/**
 *
 * Service builder
 *
 * This globally available function (via Webpack's provide plugin)
 * Creates a service, in other words, it prevents you from
 * having stuff like:
 *
 *   import angular from 'angular';
 *   import constants from 'constants';
 *   export default angular.module('configService',[constants])
 *     .factory('configService', service)
 *     .name;
 *   service.$inject = ['$http', '$log', 'BASE_URI'];
 *   function service($http, $log, BASE_URI){
 *   		// .. service code here
 *   }
 *
 * And instead have:
 *
 *   import constants from 'constants';
 *   export default builder.service({
 *     name :'configServie',
 *     function : service,
 *     dependencies : [constants],
 *     inject : ['$http', '$log', 'BASE_URI']
 *   });
 *   function service($http, $log, BASE_URI){
 *   		// .. service code here
 *   }
 *
 *
 * @param  {[type]} options.name         [description]
 * @param  {[type]} options.func         [description]
 * @param  {[type]} options.dependencies [description]
 * @param  {[type]} options.module       [description]
 * @return {[type]}                      [description]
 */
// function build(template,{name,func,dependencies,inject,module}){
// 	const mod = module ? angular.module(module) : angular.module(name,dependencies);
// 	func.$inject = inject;
//   return mod[template](name, func).name;
// }
function build(template,{name,func,inject}){

  // Get previously declared module
  const mod = angular.module('arrivedo');

  // Inject dependencies
  func.$inject = inject;

  // register it
  mod[template](name, func);

  //export its name
  return name;
}
