import angular from 'angular';
import 'angular-ui-router';
import 'oclazyload';
import '/node_modules/magnific-popup/dist/magnific-popup.css';
import '/node_modules/owl.carousel/dist/assets/owl.carousel.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import './app.scss';

const app = angular.module('arrivedo',
  [
   'ui.router',
   'oc.lazyLoad'
  ]
);

app.config(["$provide", "$compileProvider", "$controllerProvider", "$filterProvider", "$urlRouterProvider",
  function ($provide, $compileProvider, $controllerProvider, $filterProvider, $urlRouterProvider) {
      app.controller = $controllerProvider.register;
      app.directive = $compileProvider.directive;
      app.filter = $filterProvider.register;
      app.factory = $provide.factory;
      app.service = $provide.service;
      app.constant = $provide.constant;
      
      // $locationProvider.html5Mode(true);
      $urlRouterProvider.otherwise('/');
  }]);

require('./routing/home.routing');
require('./routing/explore.routing');
require('./routing/developers.routing')
require('./services/utils.service');
require('./services/lazy-load.service');
require('./services/articles.service');
require('./services/article-places.service');
require('./components/footer/footer.directive');
require('./components/navbar/navbar.directive');
require('./components/article-body/article-body.directive');
require('./components/not-found/not-found.directive');