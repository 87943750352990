/**
 *
 * Utils service, just a wrapper for utils (makes no sense, 
 * I know, just didn't have time to replace every appearance of 'utils' across the app)
 *
 *
 * TODO: rename this to utilsService
 * 
 *
 */

// import utils from '../utils';


export default builder.service({
  name: 'utils',
  inject: [],
  func: service
});

function service() {
  var service = {};
  service.friendlyTime = friendlyTime;
  service.isElementInViewport = isElementInViewport;
  service.getRouteEstimate = getRouteEstimate;

  return service;

  function friendlyTime(seconds) {
    let interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval === 1 ? interval + " day" : interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval === 1 ? interval + " hour" : interval + " hrs";
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + " min";
    }
    if (isNaN(Math.floor(seconds))) {
      return '';
    }
    return Math.floor(seconds) + " secs";
  }

  function isElementInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  function getRouteEstimate(number) {
    let hours = Math.floor(number / 60);
    let minutes = number % 60;
    return hours + "h " + minutes + "m";
  }
}
