import homePage from '../pages/home/home.page';

export default homeRouting;

angular.module('arrivedo')
.config(homeRouting);

homeRouting.$inject = ['$stateProvider'];

function homeRouting($stateProvider) {
  $stateProvider
    .state('welcome', {
      url: '/',
      template: homePage.template,
      controller: homePage.controller,
      resolve: {
        lazyLoad: ['$q', '$ocLazyLoad', function ($q, $ocLazyLoad) {
          let deferred = $q.defer();
          require.ensure([], function () {
            // All the code here, plus the required modules will be bundled in a separate file.
            let module = require('../modules/home.module.js');
            let loadRes = $ocLazyLoad.inject({ //.inject({
              name: module.default
            });
            console.log('loadRes');
            console.log(loadRes);
            deferred.resolve(module);
          });
          return deferred.promise;
        }]
      }
    });
}
