import explorePage from '../pages/explore/explore.page';

export default exploreRouting;

angular.module('arrivedo')
  .config(exploreRouting);

  exploreRouting.$inject = ['$stateProvider'];

function exploreRouting($stateProvider) {

  $stateProvider
    .state('explore', {
      url: '/article/:id',
      template: explorePage.template,
      controller: explorePage.controller,
      controllerAs: 'vm',
      resolve: {
        lazyLoad: ['$q', '$ocLazyLoad', function ($q, $ocLazyLoad) {
          let deferred = $q.defer();
          require.ensure([], function () {
            let module = require('../modules/explore.module');
            $ocLazyLoad.inject({
              name: module.default
            });
            deferred.resolve(module);
          });
          return deferred.promise;
        }]
      }
    });
}