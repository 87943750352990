// import lazyLoadService from '../../services/lazy-load.service';
// import utils from '../../services/utils.service';

import template from './explore.page.html';
const mapboxgl = require('mapbox-gl');

export default {
  template,
  controller,
};

controller.$inject = ['$scope', '$stateParams', '$rootScope', 'articlesService', 'utils', '$log'];
function controller($scope, $stateParams, $rootScope, articlesService, utils, $log) {

  const vm = this;
  vm.orderedIds = undefined;
  vm.article = [];
  vm.place = {};
  $scope.place = {};
  $scope.map = {
    loaded: false
  };

  activate();

  ////////////// functions ////////////////


  async function activate() {
    console.log('activate explore controller');
    // vm.id = $stateParams.id;

    mapboxgl.accessToken = 'pk.eyJ1IjoiY29kZWpvc2VwaCIsImEiOiJjbG5zaGV5amkxYzUzMmtxZG44djRqNjR6In0.9_x7RgKbIFRtKWUhNPVF-A';
    /*     const map = new mapboxgl.Map({
          container: 'map', // container ID
          style: 'mapbox://styles/mapbox/streets-v12', // style URL
          center: [-74.5, 40], // starting position [lng, lat]
          zoom: 9, // starting zoom
        }); */

    articlesService.get().then(function (resp) {
      if (!resp[$stateParams.id] || !Object.keys(resp[$stateParams.id]).length) {
        $rootScope.$broadcast('not-found');
        return;
      }
      vm.article = resp[$stateParams.id];
      console.log(vm.article);

      $scope.article = resp[$stateParams.id];
      $rootScope.article = $scope.article;
      $scope.$broadcast('places-ready', vm.article.places);

      if (vm.article.is_route) {
        // const singlepath = vm.article.route.path.map(obpoint => obpoint.latlng);
        const singlepath = vm.article.route.path;
        $scope.$broadcast('draw-route', singlepath);

        $scope.budgetLabel = Array(parseInt(vm.article.route.budget.value) + 1).join('$');
        $scope.budgetLabelRemaining = Array(5 - parseInt(vm.article.route.budget.value)).join('$');
        $scope.effortLabel = vm.article.route.effort.label;
        $scope.estimateLabel = utils.getRouteEstimate(vm.article.route.estimate);
      }

      drawHostMarker(vm.article.org_name, vm.article.org_thumbnail, vm.article.org_lnglat);

    }).catch(function (error) {
      $log.error("Error getting article: ", error);
    });

    // Load map.
    const map = new mapboxgl.Map({
      container: 'map',
      // style: 'mapbox://styles/rodrigokipulab/cjkusktqt1lw22rkhno4rmrky',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: vm.article.org_lnglat,
      zoom: 14,
      attributionControl: false
    });

    map.on('load', () => {
      $scope.$apply(() => {
        // To update map.loaded on rootScope  
        // forcefully, use $apply function 
        $scope.map.loaded = true;
      });
      map.resize();
      const mapContainerEl = document.getElementById('map');
      mapContainerEl.style.opacity = 1;
    });

    // Add zoom and rotation controls to the map.
    map.addControl(new mapboxgl.NavigationControl({
      showCompass: false,
      showZoom: true
    }), 'top-right');

    // Load-active-place
    $rootScope.$on('load-active-place', function (e, activePlace) {
      console.log('activePlace', activePlace);

      var active_place = $rootScope.article.places.find(place => place.id === activePlace);

      var lat = active_place.latlng[0],
        lng = active_place.latlng[1];
      map.panTo(
        //bearing: 27,
        [lng, lat],
        //zoom: 14,
        //pitch: 45
      );

      /* if (active_place.foursquare_id) {
        //console.log(active_place.foursquare_id);
        getPlaceDetails(active_place.foursquare_id);
      } else {
        $scope.place.name = active_place.name;
        $scope.place.shortUrl = 'https://arrivedo.com/place/en/'+active_place.id+'?org=e27e5c1d-3284-4cfa-9a27-f2402e9788dc';
      } */
    });

    $scope.$on('draw-route', function (e, coords) {
      // const lnglts = coords.map(coord => [coord[1], coord[0]]);
      // console.log(lnglts);

      map.on('load', function () {

        map.addLayer({
          "id": "route",
          "type": "line",
          "source": {
            "type": "geojson",
            "data": {
              "type": "Feature",
              "properties": {},
              "geometry": {
                "type": "LineString",
                "coordinates": coords
              }
            }
          },
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
          "paint": {
            "line-color": "#00A7FF",
            "line-width": 8
          }
        });
      });
    });


    // Places-ready
    $scope.$on('places-ready', function (e, places) {

      const article_content = document.getElementById('article-content');

      $rootScope.$on('load-active-place', function (e, activePlace) {
        $scope.activePlaceName = activePlace;
      });

      function setActivePlace(placeName) {
        if (placeName === $scope.activePlaceName) return;

        var place = vm.article.places.find(function (place) {
          return place.id === placeName;
        });

        map.flyTo({
          bearing: 90,
          center: [place.latlng[1], place.latlng[0]],
          zoom: 15,
          speed: 0.6,
          pitch: 40
        });

        var x = document.querySelectorAll(".placeMarker.active");
        for (var i = 0; i < x.length; i++) {
          x[0].classList.remove('active');
        }

        document.getElementById('marker-' + placeName).classList.add('active');
        // document.getElementById('marker-'+activePlaceName).classList.remove('active');

        $scope.activePlaceName = placeName;
        // console.log(place.foursquare_id);
        // getPlaceDetails(place.foursquare_id);
      }

      function isElementOnScreen(id) {
        var element = document.getElementById('place-' + id);
        var bounds = element.getBoundingClientRect();
        return bounds.top < window.innerHeight && bounds.bottom > 0;
      }

      // On every scroll event, check which element is on screen
      article_content.onscroll = () => {
        var place = places.find(function (place) {
          return isElementOnScreen(place.id);
        });
        if (place) {
          setActivePlace(place.id);
        }
      };
    });


    function drawHostMarker(org_name, org_thumbnail, org_lnglat) {
      // create a DOM element for the marker
      var el = document.createElement('div');
      el.className = 'hostMarker';
      el.style.backgroundImage = 'url("' + org_thumbnail + '")';
      el.style.width = '32px';
      el.style.height = '32px';
      el.setAttribute('title', org_name);

      // Add marker to map
      new mapboxgl.Marker(el)
        .setLngLat(org_lnglat)
        .addTo(map);
    }


    $rootScope.$on('load-article-markers', function (e, article, orderedIds) {

      var order, lat, lng, placeName;

      if (article.places) {
        // console.log('yes!');
        article.places.forEach(function (place, i) {
          lat = place.latlng[0];
          lng = place.latlng[1];
          order = place.index;
          placeName = place.name;
          // console.log('>>>', lat, lng, order, placeName, place.id);
          drawPlaceMarkers(lat, lng, order, placeName, place.id, place.foursquare_id);
        });
      }

    });


    function drawPlaceMarkers(latitude, longitud, order, placeName, placeId, foursquareId) {
      // create a DOM element for the place marker
      var el = document.createElement('div');
      if (order === 0) {
        el.className = 'placeMarker optional';
      } else if (order === 1) {
        el.className = 'placeMarker active';
      } else {
        el.className = 'placeMarker';
      }
      el.style.width = '28px';
      el.style.height = '28px';
      el.innerHTML = '<span>' + order + '</span>';
      el.setAttribute('title', placeName);
      el.setAttribute('id', 'marker-' + placeId);

      el.addEventListener('click', function () {
        map.flyTo({
          bearing: 90,
          center: [longitud, latitude],
          zoom: 15,
          speed: 0.6,
          pitch: 40
        });

        var x = document.querySelectorAll(".placeMarker.active");
        for (var i = 0; i < x.length; i++) {
          x[0].classList.remove('active');
        }

        document.getElementById('marker-' + placeId).classList.add('active');
        $('html,body').animate({ scrollTop: $('#place-' + placeId).offset().top - 60 }, 'slow');

        /* if (foursquareId) {
          getPlaceDetails(foursquareId);
        } else {
          $scope.place.name = placeName;
          $scope.place.shortUrl = 'https://arrivedo.com/place/en/' + placeId + '?org=e27e5c1d-3284-4cfa-9a27-f2402e9788dc';
          console.log('que esta pasando causa?');
        } */

      });

      // add marker to map
      new mapboxgl.Marker(el)
        .setLngLat([longitud, latitude])
        .addTo(map);
    }


    $rootScope.$on('article-place-visible', function (e, placeId, placeOrder) {
      console.log(vm.article.places[placeOrder - 1]);
      var lat = vm.article.places[placeOrder - 1].latlng[0], lng = vm.article.places[placeOrder - 1].latlng[1];
      map.flyTo({ center: [lng, lat] });

      var x = document.querySelectorAll(".placeMarker.active");
      for (var i = 0; i < x.length; i++) {
        x[0].classList.remove('active');
      }

      document.getElementById('marker-' + placeId).classList.add('active');
    });

  }

}
